import { Attachment } from './Attachment';
import { LiveChannelBase } from './Broadcast';
import { User } from './User';

export enum LiveSource$Type {
  RMS = 'RMS',
  GENERIC = 'Generic',
  MUX = 'Mux',
}

export enum LiveEvent$Status {
  EXPIRED = 'Expired',
  ON_AIR = 'OnAir',
  PREVIEW = 'Preview',
  SCHEDULED = 'Scheduled',
  FINISHED = 'Finished',
}

export enum LiveEvent$Privacy {
  PUBLIC = 'Public',
  UNLISTED = 'Unlisted',
  RESTRICTED = 'Restricted',
}

// Almost 10 years, IDK why. Ask backend guys
export const UNLIMITED_EVENT_DURATION_IN_MIN = 10 * 365 * 25 * 60;
export const UNLIMITED_EVENT_DURATION_IN_SEC = UNLIMITED_EVENT_DURATION_IN_MIN * 60;

export type LiveEvent$TextOverlay = {
  enabled: boolean;
  textSpeed: number;
  backgroundColor: string;
  text: string;
  textColor: string;
};

export type LiveEvent = {
  title: string;
  description: string;
  status: LiveEvent$Status;
  startDateTime: string;
  scheduledStartTime: string;
  channels: LiveChannelBase[];
  splashPath: Nullable<string>;
  liveSourceType: LiveSource$Type;
  attachments: Attachment[];
  owner: User;
  duration: number;
  mediaId?: string;
  hasAccessCode?: boolean;
  textOverlay?: LiveEvent$TextOverlay;
} & Entity;
